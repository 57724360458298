<div class="row">
  <div class="col-md-12 grid-margin">
    <div class="row">
      <div class="col-12 col-xl-5 mb-4 mb-xl-0 p-3">
        <h4 class="font-weight-bold text-muted" i18n>
          Welcome back {{ userName }}! <span
            [ngClass]="{'bg-secondary':userData.tierLevel==='FREE','bg-primary':userData.tierLevel==='BASIC','bg-success':userData.tierLevel==='PRO','bg-business':userData.tierLevel==='BUSINESS'}"
            class="badge rounded-pill">{{userData.tierLevel}}</span>
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-8 h-100">
        <div class="col-12">
          <div class="col-12">
            <div class="row gy-1">
              <div class="col-md-3 grid-margin stretch-card">
                <div class="card shadow-sm border-start border-4"
                  style="border:none;border-color: rgba(213, 53, 53, 1)!important;">
                  <div class="card-body d-flex justify-content-between align-items-center">
                    <p class="card-title text-md-left text-xl-left mb-0" i18n>
                      <i class="fa-solid fa-calculator"></i>Extractions
                    </p>
                    <span class="mb-0 order-md-1 order-xl-0 text-muted">
                      {{userData.extractionCounter}}/{{userData.extractionLimit}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-3 grid-margin stretch-card">
                <div class="card shadow-sm border-start border-4"
                  style="border:none;border-color: rgba(213, 53, 53, 1)!important;">
                  <div class="card-body d-flex justify-content-between align-items-center">
                    <p class="card-title text-md-left text-xl-left mb-0" i18n>
                      <i class="fa-solid fa-heart-pulse"></i>
                      API Health
                    </p>
                    <span class="mb-0 order-md-1 order-xl-0 text-muted">
                      <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"
                        style="color: #157347"></span>
                      Online
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-3 grid-margin stretch-card">
                <div class="card shadow-sm border-start border-4"
                  style="border:none;border-color: rgba(213, 53, 53, 1)!important;">
                  <div class="card-body d-flex justify-content-between align-items-center">
                    <p class="card-title text-md-left text-xl-left mb-0" i18n>
                      <i class="fa-solid fa-calendar-day"></i>
                      Today
                    </p>
                    <span class="mb-0 order-md-1 order-xl-0 text-muted">
                      {{metrics.todayExtractions}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-3 grid-margin stretch-card">
                <div class="card shadow-sm border-start border-4"
                  style="border:none;border-color: rgba(213, 53, 53, 1)!important;">
                  <div class="card-body d-flex justify-content-between align-items-center">
                    <p class="card-title text-md-left text-xl-left mb-0" i18n>
                      <i class="fa-solid fa-bolt"></i>
                      Active Scraper
                    </p>
                    <span class="mb-0 order-md-1 order-xl-0 text-muted">
                      {{metrics.activeScraper}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="col-12">
            <div class="row gy-1 mt-3">
              <div class="col-md-6 grid-margin stretch-card">
                <div class="card shadow-sm mh-100" style="border:none;">
                  <div class="card-body h-100">
                    <p class="card-title" i18n>
                      <i class="fa-solid fa-chart-line"></i>
                      Extraction Statistics (last 15 days)
                    </p>
                    <canvas *ngIf="metricsReady" baseChart [data]="lineChartData" [options]="lineChartOptions"
                      [type]="lineChartType" (chartHover)="chartHovered($event)">
                    </canvas>
                    <h5 *ngIf="!metricsReady" class="text-center text-muted" i18n>No data available yet</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-6 grid-margin stretch-card">
                <div class="card shadow-sm mh-100" style="border:none;">
                  <div class="card-body h-100">
                    <p class="card-title" i18n>
                      <i class="fa-solid fa-chart-simple"></i>
                      Extraction Status overall
                    </p>
                    <canvas *ngIf="metricsReady" baseChart [data]="barChartData" [options]="barChartOptions"
                      [type]="barChartType" (chartHover)="chartHovered($event)">
                    </canvas>
                    <h5 *ngIf="!metricsReady" class="text-center text-muted" i18n>No data available yet</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="row gy-1 mt-3">
              <div class="col-md-12 grid-margin stretch-card">
                <div class="col-lg-12 grid-margin stretch-card">
                  <div class="card shadow-sm mh-100" style="border:none;">
                    <div class="card-body h-100">
                      <p class="card-title" i18n>
                        <i class="fa-solid fa-rocket"></i>
                        Getting Started
                      </p>
                      <div class="row">
                        <div class="col-3">
                          <div class="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action active text-muted container"
                              id="list-getting-started-list" data-bs-toggle="list" href="#list-getting-started"
                              role="tab" aria-controls="list-getting-started">
                              <div class="row">
                                <div class="col-2"><i class="fa-solid fa-house"></i></div>
                                <div class="col-10">Welcome</div>
                              </div>
                            </a>
                            <a class="list-group-item list-group-item-action text-muted container" id="list-docs-list"
                              data-bs-toggle="list" href="#list-docs" role="tab" aria-controls="list-docs">
                              <div class="row">
                                <div class="col-2"><i class="fa-solid fa-book"></i></div>
                                <div class="col-10">Docs</div>
                              </div>
                            </a>
                            <a class="list-group-item list-group-item-action text-muted container"
                              id="list-messages-list" data-bs-toggle="list" href="#list-messages" role="tab"
                              aria-controls="list-messages">
                              <div class="row">
                                <div class="col-2"><i class="fa-regular fa-window-restore"></i></div>
                                <div class="col-10">Create Extraction</div>
                              </div>
                            </a>
                            <a class="list-group-item list-group-item-action text-muted container"
                              id="list-settings-list" data-bs-toggle="list" href="#list-settings" role="tab"
                              aria-controls="list-settings">
                              <div class="row">
                                <div class="col-2"><i class="fa-solid fa-robot"></i></div>
                                <div class="col-10">Create Scraper</div>
                              </div>
                            </a>
                            <a class="list-group-item list-group-item-action text-muted container disabled"
                              id="list-video-list" data-bs-toggle="list" href="#list-video" role="tab"
                              aria-controls="list-video">
                              <div class="row">
                                <div class="col-2"><i class="fa-solid fa-video"></i></div>
                                <div class="col-10">Watch Tutorial</div>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div class="col-9">
                          <div class="tab-content text-muted" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="list-getting-started" role="tabpanel"
                              aria-labelledby="list-getting-started-list">
                              <p><b>Welcome to Adcolabs Scraper 👋</b></p>
                              <p>We're thrilled to have you on board. Our platform is designed to make web scraping easy
                                and accessible, whether you're a seasoned pro or just getting started. Use this section
                                to get a headstart to your webscraping journey</p>
                            </div>
                            <div class="tab-pane fade" id="list-docs" role="tabpanel" aria-labelledby="list-docs-list">
                              <p><b>Read the Documentation 📖</b></p>
                              <p> Our <a href="https://docs.scraper.adcolabs.de/">documentation</a> will help you make
                                the most of our Scraper. Whether you're looking for a quick start guide, in-depth
                                tutorials, or troubleshooting tips, you'll find it all in our docs.</p>
                              <ul>
                                <li><b> <a href="https://docs.scraper.adcolabs.de/docs/introduction/overview">Getting
                                      Started:</a></b> Learn the basics and how to use Adcolabs Scraper.</li>
                                <li><b><a
                                      href="https://docs.scraper.adcolabs.de/docs/Adcolabs-Scraper%20Web/web-app-intro">How
                                      to use this Web-Application:</a></b> Follow our guides and learn how to create
                                  extractions, building scrapers, and more.</li>
                                <li><b><a
                                      href="https://docs.scraper.adcolabs.de/docs/Adcolabs-Scraper%20API/overview">API
                                      Reference:</a></b> Explore our API documentation to integrate our services with
                                  your own applications.</li>
                                <li> <b>Troubleshooting:</b> You can use the questionmark on the buttom left to create a
                                  ticket or report a bug</li>
                              </ul>
                            </div>
                            <div class="tab-pane fade" id="list-messages" role="tabpanel"
                              aria-labelledby="list-messages-list">
                              <p><b>Create your first Extraction and extract the Data you need 🌐</b></p>
                              <p>The easiest way to get your first datasets is to create a single extraction. Getting
                                started with your first Extraction is easy. Follow these steps to create your first
                                extraction:</p>
                              <ul>
                                <li>On the Navigation choose "<b><a href="" routerLink="/create-extraction">+
                                      Extraction</a></b>"</li>
                                <li>Enter a valid URL and press <b>"Create"</b></li>
                                <li>Now wait until your extraction is finished</li>
                              </ul>
                            </div>
                            <div class="tab-pane fade" id="list-settings" role="tabpanel"
                              aria-labelledby="list-settings-list">
                              <p><b>Create and automate your first Scraper 🤖</b></p>
                              <p>The best way to create an automated Scraper is by following these 3 steps:</p>
                              <ul>
                                <li>In the navigation menu, choose "<b><a href="" routerLink="/create-scraper">+
                                      Scraper</a></b>".
                                </li>
                                <li>Enter a valid URL, choose your preferred schedule (weekly or interval-based), and
                                  press <b>"Create"</b>.</li>
                                <li>Your scraper will start as soon as the configured settings are met.</li>
                              </ul>
                            </div>
                            <div class="tab-pane fade" id="list-video" role="tabpanel"
                              aria-labelledby="list-video-list">
                              <p><b>Watch our tutorials to get started</b></p>
                              <p>The best way to create an automated Scraper is by following these 3 steps:</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card shadow-sm newsHeight" style="border: none; height: calc(100vh - 150px);">
              <div class="card-body">
                <p class="card-title" i18n>
                  <i class="fa-solid fa-newspaper"></i>
                  Latest Updates
                </p>
                <button *ngIf="isAdmin" data-bs-toggle="modal" data-bs-target="#createnewsModal" class="btn btn-primary"
                  style="color: white!important;"> Add News</button>
                <!-- Timeline 1 - Bootstrap Brain Component -->
                <section class="bsb-timeline-1 py-5 py-xl-8 sectionheight" style="overflow-y: auto; height: 100%;">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-10 col-md-10 col-xl-10">
                        <ul class="timeline">
                          <li *ngFor="let newsItem of newsDTO" class="timeline-item">
                            <div class="timeline-body">
                              <div class="timeline-content">
                                <div class="card border-0">
                                  <div class="card-body p-0">
                                    <h5>
                                      <span *ngIf="newsItem.newsType === 'UPDATE'"
                                        class="badge rounded-pill text-bg-secondary">Update</span>
                                      <span *ngIf="newsItem.newsType === 'NEWS'"
                                        class="badge rounded-pill text-bg-success">News</span>
                                    </h5>
                                    <h5 class="card-subtitle text-secondary mb-1">{{newsItem.created | date:
                                      'dd-MM-yyyy'}}</h5>
                                    <h4 class="card-title mb-3 text-muted">{{newsItem.headline}}
                                    </h4>
                                    <p class="card-text m-0 text-muted">{{newsItem.newsText}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner type="ball-pulse" bdColor="rgba(0, 0, 0, 0.6)" size="medium" color="#fff" [fullScreen]="true">
</ngx-spinner>


<!-- Create News Modal -->
<div class="modal fade" id="createnewsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="createnewsModal">Create News </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          style="color: white!important;"></button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <div class="col-sm-7" [formGroup]="form">
            <div class="form-group">
              <label for="newsHeadline" class="label-description"> Headline</label>
              <input type="text" id="newsHeadline" class="form-control" formControlName="headline">
            </div>
            <div class="form-group">
              <label for="newType" class="label-description"> News Type</label>
              <select type="text" id="newsType" class="form-control" formControlName="newsType">
                <option disabled selected value="">Choose</option>
                <option *ngFor="let type of NewsTypes" [value]="type">{{type}}</option>
              </select>
            </div>

            <div class="form-group">
              <label for="newsText" class="label-description"> News Text</label>
              <textarea class="form-control" formControlName="newsText" id="newsText" rows="3"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
        <button (click)="createNews()" data-bs-dismiss="modal" class="btn btn-primary my-2 col-md-2"
          [disabled]="!form.valid" style="color: white!important;">Create
          News</button>
      </div>
    </div>
  </div>
</div>
<div class="position-sticky pt-3">
  <ul class="nav flex-column">
    <li class="nav-item" i18n>
      <a class="nav-link active" aria-current="page" routerLink="/dashboard" routerLinkActive
        #dashbaord="routerLinkActive">
        <div class="col-12">
          <div class="row">
            <div class="col-2">
              <i class="fa-solid fa-gauge"></i>
            </div>
            <div class="col-8">
              Dashboard
            </div>
          </div>
        </div>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/scrape" routerLinkActive #scrape="routerLinkActive">
        <div class="col-12">
          <div class="row">
            <div class="col-2">
              <i class="fa-solid fa-brain"></i>
            </div>
            <div class="col-8">
              Scrape
            </div>
          </div>
        </div>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/create-extraction" routerLinkActive #createExtraction="routerLinkActive">
        <div class="col-12">
          <div class="row">
            <div class="col-3 ms-auto text-end"><i class="fa-solid fa-plus"></i></div>
            <div class="col-9">
            Extraction 
            </div>
          </div>
        </div>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/create-scraper" routerLinkActive #createScraper="routerLinkActive">
        <div class="col-12">
          <div class="row">
            <div class="col-3 ms-auto text-end"><i class="fa-solid fa-plus"></i></div>
            <div class="col-9">Scraper</div>
          </div>
        </div>
      </a>
    </li>
    <li class="nav-item pb-1" i18n>
      <a class="nav-link" routerLink="/extractors" routerLinkActive #account="routerLinkActive">
        <div class="col-12">
          <div class="row">
            <div class="col-2">
              <i class="fa-solid fa-braille"></i>
            </div>
            <div class="col-8">
              Extractors
            </div>
          </div>
        </div>
      </a>
    </li>
    <li class="nav-item pb-1" i18n>
      <a class="nav-link" routerLink="/api" routerLinkActive #api="routerLinkActive">
        <div class="col-12">
          <div class="row">
            <div class="col-2">
              <i class="fa-solid fa-circle-nodes"></i>
            </div>
            <div class="col-8">
              API
            </div>
          </div>
        </div>
      </a>
    </li>
    <hr>
    <li class="nav-item pb-1" i18n>
      <a class="nav-link" routerLink="/account" routerLinkActive #account="routerLinkActive">
        <div class="col-12">
          <div class="row">
            <div class="col-2">
              <i class="fa-solid fa-user-gear"></i>
            </div>
            <div class="col-8">
              Account
            </div>
          </div>
        </div>
      </a>
    </li>
    <li class="nav-item pb-1" i18n>
      <a class="nav-link" routerLinkActive routerLink="/pricing" routerLinkActive #pricing="routerLinkActive">
        <div class="col-12">
          <div class="row">
            <div class="col-2">
              <i class="fa-solid fa-store"></i>
            </div>
            <div class="col-8">
              Subscription
            </div>
          </div>
        </div>
      </a>
    </li>

    <li class="nav-item pb-1" i18n>
      <a class="nav-link" href="https://docs.scraper.adcolabs.de/" target="_blank" rel="noopener noreferrer">
        <div class="col-12">
          <div class="row">
            <div class="col-2">
              <i class="fa-solid fa-link"></i>
            </div>
            <div class="col-8">
              Documentation
            </div>
          </div>
        </div>
      </a>
    </li>
  </ul>
</div>
<div class="col-12 grid-margin">
    <div class="card shadow-sm col-12">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="card-body">
                    <div class="col-12">
                        <h2 class="card-title" i18n>Scraper </h2>
                    </div>
                    <p class="text-muted font-weight-light" i18n>{{name.value}}</p>
                    <form [formGroup]="form" (ngSubmit)="submitData()">
                        <div class="row col-md-12">
                            <div class="col-md-4">
                                <div class="row col-md-12 p-1">
                                    <div class="col-md-4">
                                        <label class="col-form-label">Created</label>
                                    </div>
                                    <div class="col-md-8">
                                        <input readonly class="form-control" formControlName="created"
                                            value="{{scraperData.created | date :'medium'}}">
                                    </div>
                                </div>
                                <div class="row col-md-12 p-1">
                                    <div class="col-md-4">
                                        <label class="col-form-label">Scheduled</label>
                                    </div>
                                    <div class="col-md-8">
                                        <input readonly class="form-control" formControlName="lastSchedule"
                                            value="{{scraperData.lastSchedule | date:'medium'}}">
                                    </div>
                                </div>
                                <div class="row col-md-12 p-1" formGroupName="extraction">
                                    <div class="col-md-4">
                                        <label class="col-form-label">URL</label>
                                    </div>
                                    <div class="col-md-8">
                                        <input class="form-control" formControlName="url" value="{{url}}">
                                    </div>
                                </div>
                                <div class="row col-md-12 p-1" formGroupName="extraction">
                                    <div class="col-md-4">
                                        <label class="col-form-label">Proxy</label>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="col-sm-12 col-12 col-md-12" formGroupName="connectivity">
                                            <select class="form-control form-control" name="proxy"
                                                formControlName="proxy">
                                                <option value="" selected disabled i18n>
                                                    Select
                                                </option>
                                                <option *ngFor="let proxy of proxyList" [ngValue]="proxy">
                                                    {{ proxy | titlecase}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-md-12 form-group" formGroupName="extraction">
                                    <div class="col-md-4">
                                        <label class="col-form-label">Resolution</label>
                                    </div>
                                    <div class="col-md-8" formGroupName="agent">
                                        <div class="col-md-12 pt-1" formGroupName="resolution">
                                            <div class="form-group">
                                                <div class="input-group p-1">
                                                    <span class="input-group-text" style="width: 70px;"
                                                        i18n>Width</span>
                                                    <input type="text" class="form-control" formControlName="width">
                                                </div>
                                                <div class="input-group p-1">
                                                    <span class="input-group-text" style="width: 70px;"
                                                        i18n>Height</span>
                                                    <input type="text" class="form-control" formControlName="height">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" formGroupName="extraction">
                                <div class="row col-md-12 form-group">
                                    <div class="col-md-4">
                                        <label class="col-form-label">Headers</label>
                                    </div>
                                    <div class="col-md-8 form-check" formGroupName="agent" style="padding-left: 10px;">
                                        <div class="col-md-12" formGroupName="options">
                                            <div *ngIf="headers.length === 0" class="form-group card">
                                                <ul class="list-group list-group-flush">
                                                    <li class="list-group-item" style="padding-left: 10px;">No Headers
                                                        Added</li>
                                                </ul>
                                            </div>
                                            <div *ngIf="headers.length > 0" class="card">
                                                <ul class="list-group list-group-flush">
                                                    <li *ngFor="let header of headers.value; let i=index"
                                                        class="list-group-item">
                                                        <b>{{i+1}}</b> {{header.key}} : {{header.value}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" formGroupName="extraction">
                                <div class="row col-md-12 form-group" formGroupName="webhook">
                                    <div class="col-md-3">
                                        <label class="col-form-label">Webhook</label>
                                        <div class="form-check form-switch" style="padding-left: 0px;">
                                            <input class="form-check-input" type="checkbox" formControlName="enabled"
                                                style="margin-left: 0px; padding: 2px;">
                                            <label class="form-check-label" i18n>Active</label>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="row">
                                            <div class="btn-group" role="group"
                                                aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="method" value="GET" id="get"
                                                    formControlName="method">
                                                <label class="btn btn-outline-primary" for="get">GET</label>
                                                <input type="radio" class="btn-check" name="method" value="POST"
                                                    id="post" formControlName="method">
                                                <label class="btn btn-outline-primary" for="post">POST</label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div *ngIf="webhookEnabled" class="col-md-12 pt-1">
                                                <div class="input-group p-1 col-md-12">
                                                    <span class="input-group-text" style="width: 70px;" i18n>URL</span>
                                                    <input type="text" class="form-control" formControlName="url">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-md-12 p-1">
                                            <div class="col-md-4">
                                                <label class="col-form-label">Headers:</label>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="col-md-12" formGroupName="header">
                                                    <div *ngIf="webhookHeader.length === 0" class="form-group card">
                                                        <ul class="list-group list-group-flush">
                                                            <li class="list-group-item">No Headers Added</li>
                                                        </ul>
                                                    </div>
                                                    <div *ngIf="webhookHeader.length > 0" class="col-md-12 col-sm-12">
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <input
                                                                    *ngFor="let header of webhookHeader.value; let i=index"
                                                                    class="form-control col-md-6 m-1"
                                                                    disabled="header.disabled" value="{{header.key}}">
                                                            </div>
                                                            <div class="col-6">
                                                                <input
                                                                    *ngFor="let header of webhookHeader.value; let i=index"
                                                                    class="form-control col-md-6 m-1"
                                                                    disabled="header.disabled" value="{{header.value}}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class=" col-lg-4 col-md-6 col-sm-12 col-12" formGroupName="extraction">
                                <div class="row col-md-12 form-group">
                                    <div class="col-md-5">
                                        <label class="col-form-label">
                                            <h5 i18n>Browser Action</h5>
                                        </label>
                                    </div>
                                    <!-- Hier kommen Browser Actions rein-->
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div *ngIf="browserActionsArray.at(1)?.value.action !== undefined"
                                            class="card my-2">
                                            <ul class="list-group list-group-flush">
                                                <li *ngFor="let browserAction of workflow; let i=index"
                                                    class="list-group-item">
                                                    <b>{{i+1}} -</b>
                                                    <span class="badge rounded-pill bg-info small mx-1" id="badges">
                                                        <span *ngIf="browserAction.action==='screenshot'"
                                                            title="Screenshot">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="currentColor" class="bi bi-camera"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                                                <path
                                                                    d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                                            </svg>
                                                        </span>
                                                        <span *ngIf="browserAction.action==='full-page-screenshot'"
                                                            title="Screenshot-Full">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="currentColor"
                                                                class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd"
                                                                    d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
                                                            </svg>
                                                        </span>
                                                        <span *ngIf="browserAction.action==='wait'" title="Wait">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="currentColor"
                                                                class="bi bi-hourglass-split" viewBox="0 0 16 16">
                                                                <path
                                                                    d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                                                            </svg>
                                                        </span>
                                                        <span *ngIf="browserAction.action==='click'" title="Click">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="currentColor" class="bi bi-mouse2"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M3 5.188C3 2.341 5.22 0 8 0s5 2.342 5 5.188v5.625C13 13.658 10.78 16 8 16s-5-2.342-5-5.188V5.189zm4.5-4.155C5.541 1.289 4 3.035 4 5.188V5.5h3.5V1.033zm1 0V5.5H12v-.313c0-2.152-1.541-3.898-3.5-4.154zM12 6.5H4v4.313C4 13.145 5.81 15 8 15s4-1.855 4-4.188V6.5z" />
                                                            </svg>
                                                        </span>
                                                        <span *ngIf="browserAction.action==='scroll'" title="Scroll">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="currentColor"
                                                                class="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd"
                                                                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
                                                            </svg>
                                                        </span>
                                                    </span>
                                                    <b>{{browserAction | workflow}}</b>
                                                </li>
                                            </ul>
                                        </div>
                                        <div *ngIf="browserActionsArray.at(1)?.value.action === undefined "
                                            class="card my-2">
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item">No Browser Actions</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="col-12">
                            <div class="col-12">
                                <fieldset class="row mb-3">
                                    <legend class="col-form-label col-5 pt-0">
                                        <h5 i18n>Schedule Settings</h5>
                                    </legend>
                                    <div class="col-md-12" class="form-group" formGroupName="scheduleDetails">
                                        <div class="form-check col-md-6">
                                            <input class="form-check-input" type="radio" formControlName="scheduleType"
                                                value="weekly" (change)="onChangeIntervalType($event)">
                                            <label class="form-check-label" for="gridRadios1" i18n> Weekly</label>
                                        </div>
                                        <div class="form-check col-md-6">
                                            <input class="form-check-input" type="radio" formControlName="scheduleType"
                                                value="interval" (change)="onChangeIntervalType($event)">
                                            <label class="form-check-label" for="gridRadios2" i18n>
                                                Interval
                                            </label>
                                        </div>
                                        <div class="col-md-12">
                                            <div *ngIf="scheduleType.value === 'interval'" class="form-group col-md-2">
                                                <div class="input-group p-1">
                                                    <span class="input-group-text" style="width: 80px;"
                                                        i18n>Minute</span>
                                                    <input type="text" class="form-control" formControlName="minute">
                                                </div>
                                                <div class="input-group p-1">
                                                    <span class="input-group-text" style="width: 80px;" i18n>Hour</span>
                                                    <input type="text" class="form-control" formControlName="hour">
                                                </div>
                                                <div class="input-group p-1">
                                                    <span class="input-group-text" style="width: 80px;" i18n>Day</span>
                                                    <input type="text" class="form-control" formControlName="day">
                                                </div>
                                                <div style="color:red"
                                                    *ngIf="minute.invalid && minute.touched && minute.dirty" i18n>
                                                    Please provide a valid Value for minutes (default value should be 0)
                                                    <br>
                                                </div>
                                                <div style="color:red" *ngIf="hour.invalid && hour.touched" i18n>
                                                    Please provide a valid Value for hours (default value should be
                                                    0)<br>
                                                </div>
                                                <div style="color:red" *ngIf=" day.invalid && day.touched" i18n>
                                                    Please provide valid Values for day (default value should be 0)<br>
                                                </div>
                                            </div>
                                            <div *ngIf="scheduleType.value === 'weekly'"
                                                class="col-md-6 col-lg-6 col-12 col-sm-12 card">
                                                <div class="row">
                                                    <div *ngFor="let day of dayLabels" class="col text-center">
                                                        <strong>{{ day }}</strong>
                                                        <div *ngIf="getDay(day.toUpperCase())?.length">
                                                            <div *ngFor="let time of getDay(day.toUpperCase()).controls"
                                                                class="text-center text-muted">
                                                                {{ time.value }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row mb-3 text-start">
                            <div class="col-md-12">
                                <!-- <button type="submit" class="btn btn-primary m-2" [disabled]="form.invalid" i18n>Save</button> -->
                                <button class="btn btn-outline-secondary m-2" routerLink="/scrape" i18n>Back</button>
                                <a data-bs-target="#exampleModal" data-bs-toggle="modal"
                                    class="btn btn-outline-danger m-2 float-end" i18n>DELETE
                                    <i class="fa-regular fa-trash-can"></i>
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Delete-->
<div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModal" i18n>Delete Schedule</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p i18n><small>This will permanently delete this scheduling Task. It will deleted and stop scheduling
                        immediately</small></p>
                <p i18n> <b>Are you sure you want to Delete this Scheduling Task?</b></p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" i18n>Cancel</button>
                <button (click)="delete()" data-bs-dismiss="modal" class="resetbutton btn btn-danger" i18n>Delete
                    <i class="fa-regular fa-trash-can"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12 my-2">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Account</h4>
                <hr>

                <div class="col-md-8">
                    <div *ngIf="mailUpdated" class="alert alert-success col-8 alert-dismissible fade show" role="alert">
                        <p>We did send you an email to your new adress. Please check you inbox to confirm our mail.</p>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div *ngIf="passwordUpdated" class="alert alert-success col-8 alert-dismissible fade show"
                        role="alert">
                        <p>Password change mail was send. Please check your inbox.</p><button type="button"
                            class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div *ngIf="passwordUpdatedFailed" class="alert alert-danger col-8 alert-dismissible fade show"
                        role="alert">
                        <p>Error while updating your password</p><button type="button" class="btn-close"
                            data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div *ngIf="mailUpdatedFailed" class="alert alert-danger col-8 alert-dismissible fade show"
                        role="alert">
                        <p>Error while updating E-Mail.</p><button type="button" class="btn-close"
                            data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div *ngIf="!mailVerified" class="alert alert-warning col-8 alert-dismissible fade show"
                        role="alert">
                        <p>Please check your E-Mail inbox to verify your new adress</p><button type="button"
                            class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>

                    <div class="row">
                        <div class="col-md-4 d-flex flex-column justify-content-between">
                            <h5 class="text-muted">Password</h5>
                            <div class="col-md-10 mt-2">
                                <p class="text-muted small">To update your password, simply click the<br> <b>"Update
                                        Password"</b> button below, and we will send an E-Mail to you with a link to the
                                    password change page. </p>
                            </div>
                            <div class="mt-auto">
                                <button (click)="updatePassword()" class="btn btn-primary my-2"
                                    [disabled]="!mailVerified">Update Password</button>
                            </div>
                        </div>
                        <div class="col-md-4 d-flex flex-column justify-content-between">
                            <h5 class="text-muted">Email</h5>
                            <div class="col-md-10 mt-2" [formGroup]="emailForm">
                                <div class="form-group">
                                    <label for="oldEmail" class="text-muted">Active E-Mail</label>
                                    <div class="input-group">
                                        <input id="oldEmail" readonly type="email" formControlName="oldEmail"
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="form-group my-3">
                                    <label for="newEmail" class="text-muted">New E-Mail</label>
                                    <input id="newEmail" type="email" formControlName="newEmail" class="form-control"
                                        [ngClass]="{'is-invalid': (newEmail?.touched && newEmail?.invalid) || oldEmail?.value === newEmail?.value, 'is-valid': newEmail?.valid && oldEmail?.value != newEmail?.value }">
                                    <div *ngIf="oldEmail?.value === newEmail?.value" class="invalid-feedback">
                                        New email is equal to old E-Mail
                                    </div>
                                    <div *ngIf="newEmail?.dirty && newEmail?.invalid" class="invalid-feedback">
                                        This seems not to be a valid E-Mail please check it
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button (click)="updateEmail()" class="btn btn-primary my-2"
                                    [disabled]="emailForm.invalid || !oldEmailMatchesNewEmail()"
                                    data-bs-toggle="tooltip" data-bs-placement="right"
                                    data-bs-title="You will receive an email from us to verify your new address">Update
                                    E-Mail</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <h4 class="card-title">Billing</h4>
                        <div class="col-12">
                            <h6 class="text-muted">Manage Subscription & Payments</h6>
                            <p class="text-muted">To manage your subscription and your purchases you can directly go to
                                Stripe, our payment processing platform</p>
                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                (click)="manageSubscription()">
                                <span data-bs-toggle="tooltip" data-bs-placement="top"
                                    data-bs-title="Open your Stripe Dashbaord to manage all your payment and subscription related data">
                                    Stripe
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-box-arrow-up-right" viewBox="0 0 21 21">
                                        <path fill-rule="evenodd"
                                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                                        <path fill-rule="evenodd"
                                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <h4 class="card-title">Delete Account</h4>
                            <p class="text-muted">Before deleting your account, make sure to unsubscribe from all your
                                subscriptions and save the data you have extracted. This action is permanent, and you
                                will not be able to retrieve the data once it is deleted.</p>
                            <button type="button" class="btn btn-outline-danger align-self-end my-3"
                                data-bs-toggle="modal" data-bs-target="#deleteModal">
                                <i class="fa-regular fa-trash-can"></i>
                                Delete Account
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete Account Modal -->
<div class="modal fade" id="deleteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="deleteModal">Delete Account </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p>You are about to delete your account permanently.</p>
                <p>Make sure to save all your scraped data since it will be irretrievable for us.</p>
                <p>Once you've confirmed the deletion of your account, we will proceed to deactivate it; after a 30-day
                    period, all your account information will be permanently removed.</p>
                <p>Confirm your action by typing '<b>DELETE PERMANENTLY</b>'</p>

                <div class="row mb-3">
                    <div class="col-sm-7" [formGroup]="form">
                        <input type="text" class="form-control" id="deleteString" formControlName="deleteString"
                            [ngClass]="{'is-invalid':deleteString.touched && deleteString.invalid,'is-valid': deleteString.valid}">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
                <button (click)="deleteAccount()" data-bs-dismiss="modal" class="resetbutton btn btn-danger"
                    [disabled]="form.invalid">
                    <i class="fa-regular fa-trash-can"></i>
                    Delete Account
                </button>
            </div>
        </div>
    </div>
</div>
<ngx-spinner type="ball-pulse" bdColor="rgba(0, 0, 0, 0.6)" size="medium" color="#fff" [fullScreen]="true">
</ngx-spinner>
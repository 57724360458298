import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Workflow } from 'src/app/_model/Browseractions';
import { Days } from 'src/app/_model/Days';
import { ProxyList } from 'src/app/_model/ProxyList';
import ScraperData from 'src/app/_model/Scraperdata';
import { UpdateScraperDto } from 'src/app/_model/UpdateScraperDto';
import { ScraperService } from 'src/app/_service/scraper.service';


@Component({
  selector: 'app-scraper',
  templateUrl: './scraper.component.html',
  styleUrls: ['./scraper.component.scss']
})
export class ScraperComponent implements OnInit {

  private routeSub: Subscription;

  form: UntypedFormGroup;
  scheduleId: string;
  workflow?: Workflow[];
  proxyList = ProxyList.proxyList;
  dayLabels = Days.days;
  scraperData: ScraperData={
    id: undefined,
    name: undefined,
    schedulingEnabled: false,
    created: undefined,
    lastSchedule: undefined,
    extraction: {
      agent:{
        options:{
          headers:[]
        },
        resolution: undefined
      },
      url: '',
      browser: undefined,
      workflow: [],
      webhook: undefined,
      selector: [],
      connectivity: {
        proxy:undefined
      }
    },
    scheduleDetails:{
      scheduleType: undefined,
      weeklyScheduleDetails: undefined,
      minute :undefined,
      hour: undefined,
      day: undefined
    }
  };
  tempSchedulingEnabled: boolean = false;
  updateScraperDto: UpdateScraperDto = {
    name:null,
    schedulingEnabled: false,
    scheduleDetials:{
      scheduleType: undefined,
      weeklyScheduleDetails:undefined,
    }
  };

  constructor(private router: Router,
              private route: ActivatedRoute,
              private scraperService: ScraperService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      paramMap=>{
        this.scheduleId = paramMap.get('id')
        this.fetchScheduleData(this.scheduleId)
      });
  }

  fetchScheduleData(id: string) {
    this.initForm();
    this.scraperService.getScraperById(id).subscribe({
      next:(res)=>{
        this.scraperData = res;
        this.workflow = res.extraction.workflow
        this.updateFormData(this.scraperData);
      },
      error: (err)=>{
        console.warn('Could not load scraper-Data from Server!');
        console.log(err.message)
      }
    });
  }

  async submitData() {
    const id = this.form.get('id').value;
    this.updateScraperDto.scheduleDetials.scheduleType = this.form.get('scheduleInterval').value;
    this.updateScraperDto.schedulingEnabled =
      this.form.get('schedulingEnabled').value;
    if (this.form.get('scheduleInterval').value == 'once') {
      this.form.get('scheduleTime.minute').disable();
      this.form.get('scheduleTime.hour').disable();
      this.form.get('scheduleTime.day').disable();
      if (!this.form.get('specificTime')) {
        this.form.get('specificTimeValue').disable();
      }
    }
    this.scraperService.updateScraper(this.updateScraperDto, id).subscribe({
      next: (v) => {
        this.router.navigate(['/scrape']);
      },
      error: (e) => {
        console.log('ERROR:' + e.message);
      }
    });
  }

  async delete() {
    this.scraperService
      .deleteScraper(this.scraperData.id)
      .subscribe((res) => {
        this.router.navigate(['/scrape']);
      });
  }

  onChangeIntervalType(_event: any) {
    if (_event.target.value == 'interval') {
      this.form.get('scheduleDetails.minute').enable();
      this.form.get('scheduleDetails.hour').enable();
      this.form.get('scheduleDetails.day').enable();
    }
    if (_event.target.value == 'weekly') {
      this.form.get('scheduleDetails.minute').disable();
      this.form.get('scheduleDetails.hour').disable();
      this.form.get('scheduleDetails.day').disable();
    }
  }

  addToBrowserActionsArray(item: any) {
    this.browserActionsArray.push(this.fb.control(item));
  }

  updateFormData(data: any) {
    this.form.patchValue({
      id: data.id,
      name: data.name,
      schedulingEnabled: data.schedulingEnabled,
      scheduleDetails: data.scheduleDetails,
      created: data.created,
      lastSchedule: data.lastSchedule,
      scrape: data.scrape,
      userId: data.userId,
      extraction: data.extraction,
      width: data.extraction.agent.resolution.widthh,
      height: data.extraction.agent.resolution.height,
      proxy: data.extraction.connectivity.proxy,
      header: data.extraction.agent.options?.headers,
      webhook: {
        enabled: data.extraction.webhook?.enabled,
        url: data.extraction.webhook?.url,
        method:data.extraction.webhook?.method,
      },
      webhookHeader: data.extraction.webhook?.header,
    });

    this.dayLabels.forEach(element => {
      if (data.scheduleDetails && 
        data.scheduleDetails.weeklyScheduleDetails && 
        data.scheduleDetails.weeklyScheduleDetails[element.toUpperCase()] !== undefined) {
        data.scheduleDetails.weeklyScheduleDetails[element.toUpperCase()].forEach(
          (timeValue: any) => {
            const newTimeValue = timeValue.substring(0, 5);

          switch (element) {
            case 'Monday':
              this.Monday.push(new FormControl(newTimeValue));
              break;
            case 'Tuesday':
              this.Tuesday.push(new FormControl(newTimeValue));
              break;
            case 'Wednesday':
              this.Wednesday.push(new FormControl(newTimeValue));
              break;
            case 'Thursday':
              this.Thursday.push(new FormControl(newTimeValue));
              break;
            case 'Friday':
              this.Friday.push(new FormControl(newTimeValue));
              break;
            case 'Saturday':
              this.Saturday.push(new FormControl(newTimeValue));
              break;
            case 'Sunday':
              this.Sunday.push(new FormControl(newTimeValue));
              break;
          }
        });
      }
    });
    if(data.extraction.agent?.options != null){
      const headers = data.extraction.agent.options.headers;
      headers.forEach((element: { key: any; value: any; }) => {
        this.headers.push(
          this.fb.group({
            key: new FormControl({ value: element.key, disabled: false },Validators.required),
            value: new FormControl({ value: element.value, disabled: false },Validators.required),
          })
        );
      });
    }
    if(data.extraction.webhook?.header != null){
      const headers = data.extraction.webhook.header;
      headers.forEach((element: {key: any, value:any; })=>{
        this.webhookHeader.push(
          this.fb.group({
            key: new FormControl({ value: element.key, disabled: true },Validators.required),
            value: new FormControl({ value: element.value, disabled: true },Validators.required),
        })
        )
      })
    }
    
    if(data.extraction.workflow != null ){
      data.extraction.workflow.forEach((action: any) => {
        this.addToBrowserActionsArray(action);
      });
    }
    this.form.get('extraction').patchValue({
      url: data.extraction.url
    })

    //this.form.value.extraction.workflow;

    if (data.scheduleInterval === 'weekly') {
      this.form.get('scheduleDetails.minute').disable();
      this.form.get('scheduleDetails.minute').patchValue('');
      this.form.get('scheduleDetails.hour').disable();
      this.form.get('scheduleDetails.hour').patchValue('');
      this.form.get('scheduleDetails.day').disable();
      this.form.get('scheduleDetails.day').patchValue('');
    }
    if (data.scheduleDetails.scheduleType === 'interval') {
      this.form.get('scheduleDetails.minute').disable();
      this.form.get('scheduleDetails.hour').disable();
      this.form.get('scheduleDetails.day').disable();
    }
  }

  initForm(): void {
    if (this.form) {
      return;
    }
    this.form = this.fb.group({
      id: new UntypedFormControl(''),
      name:  new UntypedFormControl({value: '',disabled: true,}),
      schedulingEnabled: new UntypedFormControl(''),
      scheduleDetails: this.fb.group({
        weeklyScheduleDetails: this.fb.group({
          MONDAY: this.fb.array([]),
          TUESDAY: this.fb.array([]),
          WEDNESDAY: this.fb.array([]),
          THURSDAY: this.fb.array([]),
          FRIDAY: this.fb.array([]),
          SATURDAY: this.fb.array([]),
          SUNDAY: this.fb.array([])
        }),
      scheduleType: [{value:'', disabled: true},[]],
      minute: [{ value: '' }, [Validators.max(60), Validators.required]],
      hour: [{ value: '' }, [Validators.max(24), Validators.required]],
      day: [{ value: '' }, [Validators.max(31), Validators.required]],
      }),
      created: new UntypedFormControl({ value: '', disabled: true }),
      lastSchedule: new UntypedFormControl({ value: '', disabled: true }),
      extraction: this.fb.group({
        id: new UntypedFormControl(''),
        url: [{ value: '', disabled: true }, Validators.required],
        identifier: new UntypedFormControl(''),
        data: new UntypedFormControl(''),
        status: new UntypedFormControl(''),
        lastUpdate: new UntypedFormControl(''),
        workflow: new UntypedFormArray([
          this.fb.group({
            action: new UntypedFormControl({value: '',disabled: true,}),
            value: new UntypedFormControl({value: '',disabled: true,}),
            x: new UntypedFormControl({value: '',disabled: true,}),
            y: new UntypedFormControl({value: '',disabled: true,}),
          })
        ]),
        agent:this.fb.group({
          resolution: this.fb.group({
            width: new UntypedFormControl({ value: '', disabled: true }),
            height: new UntypedFormControl({ value: '', disabled: true }),
          }),
          options: this.fb.group({
            headers: this.fb.array([]),
          }),
        }),
        connectivity: this.fb.group({
          proxy: [{ value: 'europe', disabled: true }]
        }),
        webhook: this.fb.group({
          enabled: new UntypedFormControl({ value: false, disabled: true }),
          url: new UntypedFormControl({ value: '', disabled: true }),
          header: this.fb.array([]),
          method:[{ value: 'GET', disabled: true }, [Validators.required]]
        }),
      }),
    });
  }

  getDay(day: string): FormArray {
    return this.form.get(`scheduleDetails.weeklyScheduleDetails.${day}`) as FormArray;
  }

  //Getter
  get browserActionsArray() {
    return this.form.get('extraction.workflow') as UntypedFormArray;
  }
  get name (){
    return this.form.get('name')
  }

  get minute() {
    return this.form.get('scheduleDetails.minute');
  }

  get hour() {
    return this.form.get('scheduleDetails.hour');
  }

  get day() {
    return this.form.get('scheduleDetails.day');
  }

   get scheduleDetails() {
    return this.form.get('scheduleDetails');
   }

  get webhookEnabled() {
    return this.form.get('extraction.webhook.enabled');
  }

  get url(){
    return this.form.get('extraction.url');
  }

  get scheduleType(){
    return this.form.get('scheduleDetails.scheduleType');
  }

  get width() {
    return this.form.get('extraction.agent.resolution.width')
  }

  get height() {
    return this.form.get('extraction.agent.resolution.height')
  }
  get agent() {
    return this.form.get('extraction.agent')
  }

  get resolution() {
    return this.form.get('extraction.agent.resolution')
  }
  get proxy() {
    return this.form.get('extraction.connectivity.proxy')
  }
  get options() {
    return this.form.get('extrcation.agent.options')
  }

  get headers() {
    return this.form.get('extraction.agent.options.headers') as FormArray;
  }

  get webhook() {
    return this.form.get('extraction.webhook')
  }

  get webhookHeader() {
    return this.form.get('extraction.webhook.header') as FormArray;
  }

  get method() {
    return this.form.get('extraction.webhook.method')
  }

  get Monday() : FormArray{
    return this.form.get('scheduleDetails.weeklyScheduleDetails.MONDAY') as FormArray
  }

  get Tuesday() : FormArray{
    return this.form.get('scheduleDetails.weeklyScheduleDetails.TUESDAY') as FormArray
  }

  get Wednesday() : FormArray{
    return this.form.get('scheduleDetails.weeklyScheduleDetails.WEDNESDAY') as FormArray
  }

  get Thursday() : FormArray{
    return this.form.get('scheduleDetails.weeklyScheduleDetails.THURSDAY') as FormArray
  }

  get Friday() : FormArray{
    return this.form.get('scheduleDetails.weeklyScheduleDetails.FRIDAY') as FormArray
  }

  get Saturday() : FormArray{
    return this.form.get('scheduleDetails.weeklyScheduleDetails.SATURDAY') as FormArray
  }

  get Sunday() : FormArray{
    return this.form.get('scheduleDetails.weeklyScheduleDetails.SUNDAY') as FormArray
  }

  get weeklyScheduleDetails(){
    return this.form.get('scheduleDetails.weeklyScheduleDetails')
  }

}

<div class="bg-light">
    <app-navbar></app-navbar>
    <div class="container-fluid">
        <div class="row">
            <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                <app-sidebar></app-sidebar>
            </nav>
            <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <router-outlet></router-outlet>
            </main>
            <app-support *ngIf="userHasSupportLevel"></app-support>
        </div>
    </div>
    <app-footer></app-footer>
</div>

<!-- Modal -->
<div class="modal fade" id="welcomeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel"><b>Welcome to Adcolabs Scraper!</b></h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="text-muted">
                    Welcome aboard! We’re thrilled to have you with us. Dive in to explore the features tailored for
                    you—we’re here to help
                    you achieve your goals and maximize value every step of the way. Let’s get started!🚀
                </p>
                <p class="text-muted">
                    Stay in the loop with our latest updates, expert tips, and exclusive offers by subscribing to our
                    newsletter. Plus,
                    enjoy <b>10 free extractions</b> as a special gift for subscribing!
                </p>
                <small class="text-muted">You can cancel your subscription anytime by clicking the link provided in our
                    Newsleter E-Mail</small>
                <div class="pt-4"></div>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                        (change)="enableOptIn($event)">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Sign Up and get 10 Extractions for Free
                        🎁</label>
                </div>
                <br>
                <br>
                <p class="text-muted">
                    The Adcolabs Team
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                    (click)="submitOptIn()">Ok!</button>
            </div>
        </div>
    </div>
</div>
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { PaymentUserdata, UserDataDto, changeEmailDTO } from "../_model/UserDataDto";
import { UserTierLevel } from "../_model/UserTierLevelDto";
import { ApiConfiguration } from "./api-configuration";
import { BaseService } from "./base-service";
import { NewsDTO } from "../_model/NewsDTO";

@Injectable({
    providedIn:'root',
})
export class UserDataService extends BaseService{
    private RootUrl = environment.SCRAPER_UI_SERVICE;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        }),
    };

    constructor(config:ApiConfiguration, http:HttpClient){
        super(config,http);
    }

    getUserData():Observable<UserDataDto>{
        return this.http.get<UserDataDto>(this.RootUrl + '/userdata')
    }
    getUserDataForDashbaord():Observable<UserDataDto>{
        return this.http.get<UserDataDto>(this.RootUrl + '/userdata/dashboard')
    }

    getUserTierLevel():Observable<UserTierLevel>{
        return this.http.get<UserTierLevel>(this.RootUrl + '/userdata/usertier')
    }

    getPaymentUserData():Observable<PaymentUserdata>{
        return this.http.get<PaymentUserdata>(this.RootUrl + '/userdata/payment-usertier')
    }

    getNews():Observable<NewsDTO[]>{
        return this.http.get<NewsDTO[]>(this.RootUrl + '/news' )
    }

    createNews(newsDTO:NewsDTO):Observable<NewsDTO[]>{
        return this.http.post<NewsDTO[]>(this.RootUrl + '/news',newsDTO)
    }

    deleteUser():Observable<void>{
        return this.http.delete<void>(this.RootUrl + '/userdata')
    }

    updatePassword():Observable<HttpResponse<void>>{
        return this.http.post<void>(this.RootUrl + '/userdata/password',null,{observe: 'response'})
    }

    updateEmail(changeEmailDTO: changeEmailDTO):Observable<HttpResponse<void>>{
        return this.http.patch<void>(this.RootUrl + '/userdata/email', changeEmailDTO, {observe: 'response'})
    }

    toggleExtractorForUser(extractorId:string):Observable<UserDataDto> {
        const params = new HttpParams().set('id', extractorId)
        return this.http.patch<UserDataDto>(this.RootUrl + '/userdata/extractor', null, {params})
    }

    submitOptIn(optIn:boolean):Observable<HttpResponse<void>>{
        const params = new HttpParams().set('optIn', optIn)
        return this.http.patch<HttpResponse<void>>(this.RootUrl + '/userdata/newsletter', {observe: 'response'}, {params})
    }

}